!function ($) {
    'use strict';

    window.ap2 = {
        /**
         * Base url
         */
        root: '/',
        /**
         * Teljes elérési útvonal nyelvi kóddal, város névvel
         */
        fullRoot: '/hu/sopron',

        mall: 'sopron',
        /**
         *
         */
        storeSlidePending: false,
        /**
         *
         */
        selectedStorePos: '',
        /**
         * épület szintet meghatározó szabály
         */
        floorRule: {'sopron': {'a': 11, 'b': 31, 'c': 42}, 'keszthely': {'a': 21}},
        /**
         * üzlet logók aktív sorának indexe
         */
        storeLogoRow: -1,
        /**
         * Oldal nyelve
         */
        locale: 'hu',
        /**
         * Oldal betöltésekor mentett árfolyam értéke
         */
        exchangeRate: 0,
        /**
         *
         */
        fixNavHover: false,

        shopLayoutMode: 'basic',

        token: {},

        bcardSlideUpNeeded: true,
        /**
         * Init function
         */
        init: function () {
            if (this.fixNavHover) {
                $('nav').css({zIndex: 1});
                // sziluettnavigáció z-index bugfix
                $('nav ul li a').hover(
                    function () {
                        $('nav').css({zIndex: 10});
                    },
                    function () {
                        $('nav').css({zIndex: 1});
                    }
                );
            }

            $('header select[name=language-switch]').on('change', function () {
                window.location.href = window.ap2.root + '/' + $(this).val() + '/' + window.ap2.mall;
            });

            $('.mailto').each(function () {
                var email = $(this).data('email');
                var rev = email.replace(/[a-zA-Z]/g, function (c) {
                    return String.fromCharCode((c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
                });
                $(this).attr('href', 'mailto:' + rev);
            });

            $('a.colorbox').colorbox();

            if (typeof $.cookie('cookielaw') == 'undefined') {
                $('.cookielaw').removeClass('invisible');
            }
        },

        /**
         * Üzletek listanézet elrejtése/megjelenítése
         *
         * @param event
         */
        toggleListView: function (event) {
            event.preventDefault();
            $('.tab-content').slideToggle({
                start: function () {
                    // css column miatt az összezárá okozta magasságcsökkenés furcsa átrendezést idéz elő
                    // ezért jobb az elemeket elrejteni mielőtt ez a magasság csökkenés elkezdődik
                    $('.tab-content a').hide();
                },
                done: function () {
                    $('.tab-content a').show();
                    if ($('.tab-content').is(':visible')) {
                        $('.tab-header .control .double-arrow-down').removeClass('double-arrow-down').addClass('double-arrow-up');
                    } else {
                        $('.tab-header .control .double-arrow-up').removeClass('double-arrow-up').addClass('double-arrow-down');
                    }
                }
            });
        },

        /**
         * Egy üzlet logójára kattintás
         *
         * @param {int} storeId
         * @param {Object} event
         * @return {boolean}
         */
        setStore: function (storeId, event) {
            event.preventDefault();

            if (this.storeSlidePending) {
                return false;
            }

            var element = event.target.tagName.toLowerCase() == 'a' ? $(event.target) : $(event.target).parent(),
                store = storesData[storeId],
                card = element.parents('.shop-icons').find('.bcard'),
                floor = (this.isGroundFloor(store.position) ? 'ground-floor' : 'first-floor'),
                oppositeFloor = floor == 'ground-floor' ? 'first-floor' : 'ground-floor',
                tab = $('.tabbed[data-floor=' + floor + '] .map-area'),
                idx = $('section.shop-logos .shop-icons').index(element.parents('.shop-icons'));

            card.find('[data-image] img').attr({
                src: this.root + '/img/shop-intro-minta-' + this.mall + '.png'
            });

            //console.info(idx);
            if (this.storeLogoRow == idx) {
                //console.log(idx);
                //$.smoothScroll({offset: card.offset().top - 164});
            } else {
                if (this.bcardSlideUpNeeded) {
                    // összes névjegy felcsukása
                    $('.bcard').slideUp(300);
                }

                this.storeSlidePending = true;
                card.slideDown(300, $.proxy(function () {
                    //$.smoothScroll({offset: card.offset().top - 164});

                    this.storeSlidePending = false;
                }, this));
            }


            if (!tab.is(':visible')) {
                $('.tabbed[data-floor=' + oppositeFloor + '] .map-area').hide();
                tab.slideDown();
            }

            this.activeStore(storeId, element, false);
        },

        /**
         * Aktív üzlet - amelynek részletei megjelennek - meghatározása
         *
         * @param storeId az üzlet azonosítója
         * @param element az üzlet <a/> tag jQuery objektum
         * @param showCard bool kártya megjelenítése vagy sem
         */
        activeStore: function (storeId, element, showCard) {
            if (element.hasClass('active')) {
                return false;
            }
            var fields = ['name', 'description', 'telephone', 'link', 'email', 'openings'],
                store = storesData[storeId],
                floor = (this.isGroundFloor(store.position) ? 'ground-floor' : 'first-floor'),
                card = element.parents('.shop-icons').find('.bcard'),
                _showCard = typeof showCard == 'undefined' ? true : showCard;

            // térkép feletti logó
            $('.tabbed[data-floor=' + floor + '] .store-id').removeClass().addClass('store-id shop-' + store.logo_class);

            // kép
            card.find('[data-image] img').attr({
                src: this.root + '/img/shops/' + storeId + '.jpg',
                alt: store.name
            }).on('error', function () {
                $(this).attr('src', window.ap2.root + '/img/shop-intro-minta-' + store.city + '.png');
            });

            $('.shop a.colored').removeClass('active');
            element.addClass('active');
            window.location.hash = '!shop' + storeId;

            if (_showCard) {
                card.show();
                $.smoothScroll({offset: card.offset().top - 164});
            }

            for (var f in fields) {
                if (fields.hasOwnProperty(f)) {
                    card.find('[data-' + fields[f] + ']').html(store[fields[f]]);
                }
            }

            this.storeLogoRow = $('section.shop-logos .shop-icons').index(element.parents('.shop-icons'));
            this.setActiveStateByPosition(store.position);
        },

        goToStore: function (storeId, event) {
            event.preventDefault();

            if (this.shopLayoutMode == 'isotope') {
                this.displayAllShop(event, function () {
                    var elem = $('.shop-icons a[data-id=' + storeId + ']'),
                        card = elem.parents('.shop-icons').find('.bcard');

                    elem.trigger('click');

                    setTimeout(function () {
                        $.smoothScroll({offset: card.offset().top - 164});
                    }, 310);
                });

                return true;
            }

            var elem = $('.shop-icons a[data-id=' + storeId + ']'),
                card = elem.parents('.shop-icons').find('.bcard');

            elem.trigger('click');
            setTimeout(function () {
                $.smoothScroll({offset: card.offset().top - 164});
            }, 310);
        },

        /**
         * Prezentációs adat betöltése
         *
         */
        makeMap: function (supermarket) {
            $.getJSON(this.root + '/maps/' + supermarket + '.json', function (result) {
                window.ap2.loadMap(supermarket, result, function () {
                    //console.log('after done');
                    if (window.location.hash.length > 0) {
                        if (window.location.hash.substr(2, 4) == 'shop') {
                            var storeId = window.location.hash.substr(6);
                            $('.shop-icons a[data-id=' + storeId + ']').trigger('click');
                        }
                    }

                    $('section.footer .vertical .col-2 a').attr('href', '#').on('click', function (event) {
                        event.preventDefault();

                        $.smoothScroll({offset: 280});
                    });
                });
            });
        },

        /**
         * Térkép betöltése
         *
         * @param supermarket string
         * @param data object
         */
        loadMap: function (supermarket, data, afterDone) {
            var afterDoneFn = typeof afterDone == 'function' ? afterDone : $.noop(),
                complete = 0;
            for (var floor in data) {

                if (!data.hasOwnProperty(floor)) {
                    console.warn('property not found: '.floor);
                    return false;
                }

                var canvas = new fabric.Canvas(floor);
                $('#' + floor).get(0).fabric = canvas;

                (function (c, f, that) {

                    c.on('mouse:over', function (e) {
                        if (e.target.path.name == '0') {
                            return false;
                        }

                        e.target.setFill('#ff0000');
                        c.renderAll();
                    });

                    c.on('mouse:out', function (e) {
                        if (e.target.path.name == '0') {
                            return false;
                        }

                        if (e.target.path.name !== that.selectedStorePos) {
                            e.target.setFill('#848484');
                            c.renderAll();
                        }
                    });

                    c.on('object:selected', function (e) {
                        if (e.target.path.name == '0') {
                            return false;
                        }

                        for (var elem in storesData) {
                            if (storesData.hasOwnProperty(elem) && storesData[elem].position == e.target.path.name) {
                                $('.bcard').hide();
                                that.activeStore(elem, $('.shop-icons a[data-id=' + elem + ']'));
                                return true;
                            }
                        }
                        console.warn(e.target.path.name + ' not found');
                    });

                    // épület kerete
                    fabric.loadSVGFromURL(that.root + '/maps/' + supermarket + '-' + floor + '.svg', function (objects, options) {
                        var obj = fabric.util.groupSVGElements(objects, options);

                        obj.hoverCursor = 'default';
                        obj.perPixelTargetFind = true;
                        obj.hasControls = false;
                        obj.hasBorders = false;
                        //noinspection JSValidateTypes
                        obj.path = {name: '0'};

                        obj.lockMovementX = true;
                        obj.lockMovementY = true;

                        c.add(obj);
                        c.renderAll();
                        c.sendToBack(obj);
                    });

                    // üzletek
                    that.asyncLoop(data[f].length, function (loop) {
                        var elem = data[f][loop.iteration()];

                        fabric.loadSVGFromString(elem.path, function (objects, options) {
                            var obj = fabric.util.groupSVGElements(objects, options);

                            obj.hoverCursor = 'pointer';
                            obj.perPixelTargetFind = true;
                            obj.hasControls = false;
                            obj.hasBorders = false;
                            obj.set({fill: '#848484'});
                            //noinspection JSValidateTypes
                            obj.path = {name: elem.name};

                            obj.lockMovementX = true;
                            obj.lockMovementY = true;

                            c.add(obj);
                            c.renderAll();

                            loop.next();
                        });
                    }, function () {
                        complete++;
                        if (complete == 2) {
                            afterDoneFn();
                        }
                    });

                })(canvas, floor, this);

            }
        },

        isGroundFloor: function (pos) {
            var building = pos.substr(0, 1);
            //console.log(this.floorRule[this.mall][building] + '<=' + pos.substr(1));
            return parseInt(this.floorRule[this.mall][building]) >= parseInt(pos.substr(1));
        },

        setActiveStateByPosition: function (pos) {
            this.selectedStorePos = pos;
            this.resetMapObjectHoverState();

            $.each(['#ground-floor', '#first-floor'], function (i, item) {
                if ($(item).length > 0) {
                    var f = $(item).get(0).fabric;
                    f.getObjects().map(function (obj) {
                        //console.log(obj.path.name + '==' + pos);
                        if (obj.path.name == pos) {
                            return obj.set({fill: '#ff0000'});
                        }
                    });

                    f.renderAll();
                }
            });
        },

        /**
         * Piros kijelölés eltávolítása az üzlet térképekről
         *
         * @return object
         */
        resetMapObjectHoverState: function () {
            $.each(['#ground-floor', '#first-floor'], function (i, item) {
                if ($(item).length > 0) {
                    var f = $(item).get(0).fabric;
                    f.getObjects().map(function (obj) {
                        if (obj.path.name !== '0') {
                            return obj.set({fill: '#848484'});
                        }
                    });

                    f.renderAll();
                }
            });
        },

        toggleMap: function (e) {
            e.preventDefault();

            var contentEl = $(e.target).parents('.tabbed').find('.map-area');
            contentEl.slideToggle({
                done: function () {
                    console.log(contentEl.is(':visible'));
                    if (contentEl.is(':visible')) {
                        $(e.target).parents('h2.headline').find('.double-arrow-down').removeClass('double-arrow-down').addClass('double-arrow-up');
                    } else {
                        $(e.target).parents('h2.headline').find('.double-arrow-up').removeClass('double-arrow-up').addClass('double-arrow-down');
                    }
                }
            });
        },

        scrollToMap: function (e) {
            e.preventDefault();
            setTimeout(function () {
                $.smoothScroll({offset: $('.tabbed .map-area:visible').offset().top-65});
            }, 250);
        },

        /**
         * Aszinkron iteráció
         *
         * @param iterations int
         * @param func function
         * @param callback function
         * @returns {{next: Function, iteration: Function, break: Function}}
         */
        asyncLoop: function (iterations, func, callback) {
            var index = 0;
            var done = false;
            var loop = {
                next: function () {
                    if (done) {
                        return;
                    }

                    if (index < iterations) {
                        index++;
                        func(loop);

                    } else {
                        done = true;
                        if ($.isFunction(callback)) {
                            callback();
                        }
                    }
                },

                iteration: function () {
                    return index - 1;
                },

                break: function () {
                    done = true;
                    if ($.isFunction(callback)) {
                        callback();
                    }
                }
            };
            loop.next();
            return loop;
        },

        /**
         * Kapcsolat fejléc térkép
         */
        googleMap: function (city) {
            var coords = {
                'sopron': new google.maps.LatLng(47.672587, 16.611457),
                'keszthely': new google.maps.LatLng(46.773412, 17.264618)
            };

            var map = new google.maps.Map($('#gmap').get(0), {
                center: coords[city],
                scrollwheel: false,
                //disableDefaultUI: true,
                panControl: false,
                zoomControl: true,
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.SMALL,
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                overviewMapControl: false,
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });

            new google.maps.Marker({
                map: map,
                animation: google.maps.Animation.DROP,
                position: coords[city]
            });
        },

        homeMap: function (city) {
            var coords = {
                'sopron': new google.maps.LatLng(47.672587, 16.611457),
                'keszthely': new google.maps.LatLng(46.773412, 17.264618)
            };

            var customStyle = new google.maps.StyledMapType(
                [
                    {featureType: 'poi', elementType: 'labels', stylers: [{visibility: 'off'}]},
                    {featureType: 'road', elementType: 'geometry.stroke', stylers: [{visibility: 'off'}]},
                    {featureType: 'road.highway', elementType: 'all', stylers: [{saturation: -70}]},
                    {featureType: 'all', stylers: [{hue: '#00ff91'}]}
                ],
                {name: 'ap2home'}
            );

            var map = new google.maps.Map($('#home-map').get(0), {
                center: coords[city],
                scrollwheel: false,
                disableDefaultUI: true,
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });

            map.mapTypes.set('ap2home', customStyle);
            map.setMapTypeId('ap2home');

            new google.maps.Marker({
                map: map,
                animation: google.maps.Animation.DROP,
                position: coords[city]
            });
        },

        /**
         * Hírek lista
         */
        newsList: function () {
            var grid = $('section.news-list .text-content').imagesLoaded(function () {
                grid.masonry({
                    itemSelector: '.news-list-item',
                    columnWidth: 312,
                    gutter: 20
                });
            });

            $('.news-list-item .img-container a').colorbox();
        },

        /**
         * Galéria lista
         */
        galleryList: function () {
            var grid = $('section.galleries .text-content').imagesLoaded(function () {
                grid.masonry({
                    itemSelector: '.gallery-item',
                    columnWidth: 216,
                    gutter: 20
                });
            });
        },

        /**
         *
         */
        bannerSlideShow: function () {
            $('section.banners .slideshow').cycle({
                fx: 'carousel',
                timeout: 0,
                carouselVisible: 3,
                slides: '> a',
                allowWrap: false,
                carouselOffset: -18,
                log: false,
                prev: '.slideshow-container .button-prev',
                next: '.slideshow-container .button-next'
            });

            //
            if (window.location.hash.length > 0) {
                if (window.location.hash.substr(1, 5) == 'akcio') {
                    var id = window.location.hash.substr(6);
                    $('.slideshow a[data-banner-id=' + id + ']').trigger('click');
                }
            }
        },

        /**
         * Kezdőoldal fejléc slider
         */
        headerSlideshow: function () {
            $('.header-slide').cycle({
                //fx: 'fade',
                slides: '> .item',
                speed: 700,
                timeout: 8000,
                log: false
            });
        },

        /**
         * Kezdőoldali akciós slideshow
         */
        homeBannerSlideshow: function () {
            $('section.featured-intro .slideshow').cycle({
                fx: 'carousel',
                timeout: 0,
                carouselVisible: 3,
                slides: '> a',
                allowWrap: false,
                log: false,
                prev: '.slideshow-container .button-prev',
                next: '.slideshow-container .button-next'
            });
        },

        /**
         * Nagyméretű bannerkép beállítása
         *
         * @param baseImg
         * @param event
         */
        setBannerImage: function (baseImg, id, event) {
            event.preventDefault();

            $('#bannerBaseImg').attr('src', baseImg);
            window.location.hash = 'akcio' + id;
        },

        /**
         * Galéria képváltók
         */
        imageSlideShow: function () {
            var mainCycle = $('.slideshow.presentation'),
                thumbCycle = $('.slideshow.thumbnails');

            mainCycle.cycle({
                fx: 'carousel',
                timeout: 0,
                carouselVisible: 1,
                slides: '> a.presentation-item',
                allowWrap: false,
                log: false,
                carouselFluid: true,
                prev: '.slideshow-container.presentation .nav-prev',
                next: '.slideshow-container.presentation .nav-next'
            });

            thumbCycle.cycle({
                fx: 'carousel',
                timeout: 0,
                carouselVisible: 5,
                slides: '> a.thumbnail-item',
                allowWrap: false,
                log: false,
                prev: '.slideshow-container.thumbnails .nav-prev',
                next: '.slideshow-container.thumbnails .nav-next'
            });

            // képnagyítás nézőkép méretű slider elemein
            $('.presentation-item').colorbox({rel: 'tag'});

            // bélyegkép slider <-> nézőkép méretű slider összekötése
            $('.thumbnail-item').on('click', function (event) {
                event.preventDefault();

                var idx = $('.thumbnail-item').index($(this));
                mainCycle.cycle('goto', idx - 1);
            });
        },

        exchange: function () {
            var txtField = $('#exchange'),
                calc = $('.calculator .inner'),
                calcWidth = calc.width();

            txtField
                .on('keydown', function (e) {
                    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                        (e.keyCode == 65 && e.ctrlKey === true) ||
                        (e.keyCode == 67 && e.ctrlKey === true) ||
                        (e.keyCode == 88 && e.ctrlKey === true) ||
                        (e.keyCode >= 35 && e.keyCode <= 39)) {
                        return;
                    }

                    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                        e.preventDefault();
                    }
                })
                .on('keyup', function () {
                    var amount = parseFloat(txtField.val()),
                        newAmount = Math.round(amount * window.ap2.exchangeRate),
                        len = newAmount.toString().split('').length;

                    if (amount == 0) {
                        return false;
                    }

                    if (len > 4) {
                        calc.width(calcWidth + (len * 10));
                    } else {
                        calc.width(calcWidth);
                    }

                    $('#rateValue').html(newAmount);
                });
        },

        /**
         * hírlevél feliratkozás overlay
         */
        openSubscribeWindow: function (event) {
            event.preventDefault();

            $.colorbox({href: this.fullRoot + '/newsletter/subscibewindow'});
        },

        /**
         * Beúszó menü megosztás linkje
         */
        openShareWindow: function (event) {
            event.preventDefault();

            $.colorbox({
                href: this.root + '/sharewindow',
                onComplete: function () {
                    stButtons.locateElements();
                }
            });
        },

        /**
         *
         */
        toggleUnsubscribeState: function (event) {
            event.preventDefault();

            $('input[name=unsubscribe]').val(1);
            $('.newsletter-subscribe form').submit();
        },

        /**
         * Scroll az oldal tetejére
         *
         * @param event
         */
        scrollTop: function (event) {
            event.preventDefault();

            window.scrollTo(0, 0);
        },

        /**
         * Összes üzlet megjelenítése
         *
         * @param event
         */
        displayAllShop: function (event, callback) {
            event.preventDefault();
            this.bcardSlideUpNeeded = true;

            if (this.shopLayoutMode == 'basic') {
                return false;
            }

            var cb = typeof callback == 'undefined' ? function () {
            } : callback;

            $.ajax({
                type: 'POST',
                url: window.ap2.root + '/switchLayout',
                data: {layout: 'basic', city: this.mall, category: ''},
                headers: this.token
            }).done(function (result) {
                window.ap2.shopLayoutMode = 'basic';
                window.ap2.storeLogoRow = -1;
                $('section.shop-logos').html(result);

                // szűrő dropdown alapértelmezett állapotba kell állítani
                $('select[name=shop-filter]').val(0);
                cb();
            });
        },

        /**
         * Üzletek menüpont üzlet szűrő
         * paraméterben megadott kategória id alapján
         *
         * @param {int} categoryId
         */
        shopFilter: function (categoryId) {
            if (window.ap2.shopLayoutMode == 'basic') {
                window.ap2.loadLayout(categoryId, function () {
                    window.ap2.setIsotope(categoryId);

                    return true;
                });
            }

            window.ap2.setIsotope(categoryId);
            $('.shopcategorytitle').text($('select[name=shop-filter] option[value=' + categoryId + ']').text());
        },

        /**
         *
         * @param categoryId
         * @param callback
         */
        loadLayout: function (categoryId, callback) {
            $.ajax({
                type: 'POST',
                url: window.ap2.root + '/switchLayout',
                data: {layout: 'isotope', city: this.mall, category: categoryId},
                headers: this.token
            }).done(function (result) {
                window.ap2.shopLayoutMode = 'isotope';
                $('section.shop-logos').html(result);

                callback();
            });
        },

        /**
         *
         * @param categoryId
         */
        setIsotope: function (categoryId) {
            var isoCnt = $('.iso-container');
            if (typeof isoCnt.isotope == 'undefined') {
                window.ap2.isotope();
            }

            isoCnt.one('arrangeComplete', function () {
                //console.log('done');
                window.ap2.storeLogoRow = 1;
                window.ap2.bcardSlideUpNeeded = false;
                $('.iso-container .shop:visible:eq(0) a').trigger('click');
            }).isotope({filter: '.cat' + categoryId});
        },

        /**
         * Isotope.js init
         */
        isotope: function () {
            $('.iso-container').isotope({
                //percentPosition: true,
                itemSelector: '.shop',
                layoutMode: 'fitRows'
            });
        },

        acceptCookieLaw: function (event) {
            event.preventDefault();

            $.cookie('cookielaw', 1, {expires: 365, path: '/'});
            $('.cookielaw').addClass('invisible');
        },

        setOpeningLang: function (lang, event) {
            event.preventDefault();

            this.locale = lang;
            $('.block.page-1').removeClass('active');
            $('p.lang-hu, p.lang-de').hide();
            $('p.lang-' + lang).show();
            $('.block.page-2').addClass('active');
        },

        setOpeningMall: function (mall, event) {
            event.preventDefault();

            window.location.href = this.root + '/' + this.locale + '/' + mall;
        }
    };

}(window.jQuery);

$(document).ready(function(){
    $('#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4').click(function(){
        $('.topmenu.mobile').toggleClass('open');
        $(this).toggleClass('open');
    });
});